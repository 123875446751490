<script>
import {
    ArrowUpIcon,
    MailIcon,
    PhoneIcon,
    YoutubeIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    GitlabIcon
} from 'vue-feather-icons';
import Navbar from "@/components/navbar";
import countTo from "vue-count-to";
/**
 * Helpcenter-overview component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        ArrowUpIcon,
        MailIcon,
        PhoneIcon,
        MapPinIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        countTo,
        GitlabIcon
    }
}
</script>

<template>
<div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section
      class="bg-half d-table w-100">


    <div class="bg-overlay" style="opacity: 0.9; margin-bottom:40px; background: url('images/background/fit-your-budget.png') center bottom;"></div>

        <div class="container mt-100 ">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="mt-100 title text-white">Get an Estimate</h4>



        <p class="my-color-1 mb-0 mb-4 mx-auto para-desc">
          Save your time to get it up and see everything OnBoard
        </p>


   

                        <div class="mt-100 mt-60 page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">

                                  <li class="breadcrumb-item"><router-link to="/support">Support</router-link></li>
                                     <li class="breadcrumb-item"><router-link to="/knowledgebase">Knowledgebase</router-link></li>
                                     <li class="breadcrumb-item active" aria-current="page">Estimate</li>



                                   
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->


    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->





    <section class="section mt-40 bg-light">



      <div class="container mb-4">
        <div class="row align-items-center mb-4 pb-2">
          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <h6 class="my-color-1">Honourable Entrepreneur!</h6>
              <h4 class="title mb-4 mb-lg-0">
                Submit an Estimate Form or 
               <br />Book an Appointment
                
              </h4>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <p class="text-muted mb-0 mx-auto para-desc">
                
                We realize that the cost of digital development service is a concern for our members. So not to worry, you will receive an expert estimate of the market price of your plan.
                       
                  

              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

    <News />
        <!--end row-->
      </div>
      <!--end container-->












<div class="container mt-100">
  <div class="row justify-content-center" id="counter">
    <div class="col-12 text-center">
      <div class="section-title">

    
        

<div class="alert mb-4  alert-primary alert-pills shadow"
                      role="alert"
                    >
                      <span class="content">
                        Anyone can submit a esimate request</span
                      >
                    </div>



        <p class="text-center">
          We've replied to more than 
          <span class="my-color-1">
            <countTo
              :startVal="1"
              :endVal="618"
              :duration="4000"
            ></countTo
            >+
          </span>
          estimate request
          
          
          <span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                    where KD Network is the digital agency management area of ' KD ' to offer digital marketing service and handle full operations. 
          Sign up and check our best website design price in bangladesh" class="my-color-1 font-weight-bold">*</span> 
        </p>







      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->

























        <div class="container mt-40">
            <div class="row align-items-center">










                <div class="col-lg-7 col-md-6 col-12">
                  <div class="card rounded bg-light shadow-lg border-0">
          <div class="card-body">

            <div class="title-heading">


              <h4 class="text-center font-weight-bold my-color-1">
                Get a free Price Estimate
            </h4>
             
               <p class="text-center font-weight-bold">
                You are just a few clicks away to get reply from us
               </p>
                      </div>      
                      
                      
                      <iframe width="100%" height="640" src="https://members.karukaj.net/forms/quote/45e759339042747f47df23db4d26987c" frameborder="0" allowfullscreen></iframe>



                    </div>       
                    
                    

                    </div>



                </div>

          







                <div class="col-lg-5 col-md-6 md-4 col-12 mt-sm-0 pt-2 pt-sm-0">




                  <div class=" carousel-cell">
<img width="100%" height="340" 
  src="images/mysvg/esimate.svg"
  class="img-fluid mover"
  alt=""
/>
</div>

<!--end col-->

<p class="text-muted mx-auto mb-4">
Let us know by filling out this simple form.
A dedicated expert from our system will reply you or inform you about project estimate with details. 
</p>

                

<h4 class="title font-weight-bold">Are you new to us?            
</h4>

<p class="text-muted font-weight-bold mb-4">You can also chat with us via our favorite channels.</p>
<div class="media contact-detail align-items-center mt-3">
<div class="icon">
  <i class="uil uil-facebook-messenger-alt icon-m-md text-dark mr-3 icon-size2 font-weight-bold"></i>
</div>
<div class="media-body content">
  <h5 class="title font-weight-bold mb-0">Messenger</h5>
  <a  target="_blank" href="https://fb.me/karukaj.digital" class="my-color-1"
    >fb.me/karukaj.digital</a>
</div>
</div>


<div class="media contact-detail align-items-center mt-3">
<div class="icon">
  <i class="uil uil-whatsapp icon-m-md text-dark mr-3 icon-size2 font-weight-bold"></i>
</div>
<div class="media-body content">
  <h5 class="title font-weight-bold mb-0">WhatsApp</h5>
  <a  target="_blank" href="https://wa.me/8801714641541?text=Hi%20there!" class="my-color-1"
    >wa.me/8801714641541</a>








    
</div>
</div>

<br>

<div class="my-color-3"><small>24 hours. That's all, it will take you to figure out the cost benefits.</small></div>















</div>











            </div>



            
        </div>
        <!--end container-->






















        <div class="container mt-40 ">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="my-color-2 mb-0">
                <span class="my-color-3 mb-0"> We believe,</span><br>
                Actions speak louder than words! 
                          </h4>
                        </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->




    </section>
    <!--end section-->
    <!-- End Section -->





    <!--end section-->
    <!-- End Section -->




    <section
      class="section bg-white pt-0"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build.png') +
          ') bottom no-repeat',
      }"
    >
  
      
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
       
              <div class="mt-4 pt-2">
                 <h5 class="mb-4"> Want to talk now ? <br>We're always there for you</h5>
                 <a href="tel:+8801714641541" class="my-color-1 h5"
                    >+880-1714-641-541</a
                  >


  
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->






    <!-- Start -->
    <div class="container-fluid px-0">
      <div class="py-5 bg-footer">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-7">
              <div class="section-title">
                <div class="media">
                  <i
                    class="uil uil-award display-4 text-white title-dark"
                  ></i>
                  <div class="media-body ml-md-4 ml-3">
                    <h4 class="font-weight-bold text-light title-dark mb-1">
                      Start a Meeting
                    </h4>
                    <p class="text-white-50 mb-0">
                     We are just a click away and<br>
can’t wait to hear from you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-5 mt-4 mt-sm-0">





              <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <phone-icon class="fea icon-m-md text-light mr-3"></phone-icon>
                            </div>
                            <div class="media-body content">
                                <h5 class="title font-weight-bold mb-0 text-light">Phone</h5>
                                <a href="tel:+8801714641541" class="my-color-1">+880 1714-641-541</a><br>
                                
                            </div>
                        </div>







            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->







        
      </div>
      <!--end div-->
    </div>
    <!--end container-->
    <!-- End -->


    




    <footer class="footer footer-bar">

            <div class="footer-wave">

<!--Content before waves-->

<!--Waves Container-->


      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              
              <p class="mycopyright">
     All the entirety of this domain is protected by copyright © 2010 - {{ new Date().getFullYear() }} <br> <small> Stream url: 
      <a href="https://www.karukaj.com.bd/"
                  target="_blank"
                  class="text-reset"
                  > karukaj.com.bd</a > | Stay with Good Works!</small>
              </p>

              
            </div>
          </div>
          <!--end col-->

          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-right mb-0">
              <li class="list-inline-item">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/american-ex.png"
                    class="avatar avatar-ex-sm"
                    title="American Express"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/discover.png"
                    class="avatar avatar-ex-sm"
                    title="Discover"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/master-card.png"
                    class="avatar avatar-ex-sm"
                    title="Master Card"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/paypal.png"
                    class="avatar avatar-ex-sm"
                    title="Paypal"
                    alt=""
                /></a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/visa.png"
                    class="avatar avatar-ex-sm"
                    title="Visa"
                    alt=""
                /></a>
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->




<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>


<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>


<g class="parallax">
<use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
<use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
<use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
<use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
</g>
</svg>
</div>




    </footer>
    <!--end footer-->




    <!--end footer-->
    <!-- Footer End -->
  
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-light back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

